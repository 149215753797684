import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { ProjectInfo } from "../components/utils/Model"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
      }
    }
    projects: {
      nodes: Array<ProjectInfo>
    }
  }
}

const Featured = ({ title, description, url, image }: any) => (
  <article className="column is-6">
    <Link to={url} className="box is-special item">
      <div className="image">
        <img src={`/img/projects/${image || "spring.svg"}`} alt={title} />
      </div>
      <h1 className="is-size-4 has-text-weight-medium">{title}</h1>
      <p className="py-3">{description}</p>
    </Link>
  </article>
)

const Attic = ({ title, description, url }: any) => (
  <article className="column is-6">
    <Link to={url} className="item">
      <h1 className="is-size-5 has-text-weight-medium">{title}</h1>
      <p className="py-1">{description}</p>
    </Link>
  </article>
)

const Sort = (a: any, b: any) => {
  if (a.order === b.order) {
    return a.slug > b.slug ? 1 : -1
  }
  return a.order > b.order ? 1 : -1
}

// markup
const ProjectsTemplate = (props: Props) => {
  const page = props.data.page
  const projects = props.data.projects.nodes

  const featured = projects
    .filter(
      (project: any) =>
        project.status === "ACTIVE" &&
        project.featured &&
        !project.parentProject
    )
    .sort(Sort)
  const attic = projects
    .filter(
      (project: any) =>
        project.status === "END_OF_LIFE" && !project.parentProject
    )
    .sort(Sort)

  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="projects"
    >
      <div className="container my-6">
        <HTMLContent
          content={page.html}
          className={"markdown hero"}
        ></HTMLContent>
      </div>
      <div className="has-background-light py-6 has-big-border-bottom">
        <div className="container">
          <div className="list columns is-multiline pb-6">
            {featured.map((project: any, index: number) => (
              <Featured
                title={project.title}
                description={project.description}
                url={`/projects/${project.slug}`}
                image={project.image}
                key={`project-${index}`}
              />
            ))}
          </div>
          <h2 className="pb-5 mt-6 has-big-border-dark-top pt-5 is-size-4 has-text-weight-bold">
            Projects in the Attic
          </h2>
          <div className="list-2 columns is-multiline pb-6 mt-6">
            {attic.map((project: any, index: number) => (
              <Attic
                title={project.title}
                description={project.description}
                url={`/projects/${project.slug}`}
                image={project.image}
                key={`project-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default ProjectsTemplate

// graphQL queries
export const pageQuery = graphql`
  query ProjectsTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
      }
    }
    projects: allContentfulProject(sort: { order: ASC, fields: order }) {
      nodes {
        id
        title
        slug
        parentProject {
          id
        }
        description
        image
        order
        status
        featured
      }
    }
  }
`
